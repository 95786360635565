<template>
    <v-container fluid>
        <v-card :loading="loading">
            <v-card-title class="pa-1">
                        <span class="pl-2">
                            {{this.$t('settings.lang_pickUpNames')}}
                        </span>
            </v-card-title>
            <v-divider class="ma-0 mb-2"/>
            <v-card-text class="pa-0">
                    <v-form ref="form" v-model="valid" lazy-validation>
                        <v-row justify="center">
                            <v-col cols="6">
                                <v-text-field @focus="showTouchKeyboard"
                                              :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                              v-model="text" :label="$t('settings.lang_pickUpNames')" outlined
                                              :rules="[v=>!!v|| $t('generic.lang_requiredField')]"
                                >

                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>
            </v-card-text>
            <v-card-actions >
                <v-row no-gutters justify="center" >
                    <v-col cols="6" align="right" class="text-right">
                        <v-btn @click="create" color="primary" :disabled="!valid" large :loading="loading">
                            {{$t('generic.lang_create')}}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-container>
</template>

<script>
    import {ENDPOINTS} from "../../../config";
    import {Events} from "../../../plugins/events";
    import mixin from "../../../mixins/KeyboardMixIns";

    export default {
        name: "PickupNamesCreateComponent",
        mixins:[mixin],
        data(){
            return{
                ENDPOINTS,
                valid:false,
                text: "",
                loading:false,
            }
        },
        methods:{
            create(){
                if(!this.$refs.form.validate()){
                    return;
                }
                this.loading=true;
                this.axios.post(ENDPOINTS.SETTINGS.PICKUPNAME.CREATE,{
                    pickUpNumberName:this.text,
                }).then(res=>{
                    if(res.data){
                        this.text = "";
                        Events.$emit("showSnackbar", {
                            message: this.$t('settings.lang_pickUpNameAdded'),
                            color: "success"
                        });
                    }else{
                        Events.$emit("showSnackbar", {
                            message: res.data.msg,
                            color: "error"
                        });
                    }
                }).catch(err=>{
                    Events.$emit("showSnackbar", {
                        message: err.message,
                        color: "error"
                    });
                }).finally(fin=>{
                    this.$refs.form.reset()
                    this.loading=false;
                });
            },
        },
    }
</script>

<style scoped>

</style>