<template>
    <v-container class="pa-0" fluid>
        <page-title :heading="this.$t('settings.lang_pickUpNames')"
                    :subheading="''"
                    :icon="icon"
                    show-previous-button
        ></page-title>
        <div class="app-main__inner">
            <PickupNamesCreateComponent/>
        </div>
    </v-container>
</template>

<script>
    import PageTitle from "../../../Layout/Components/PageTitle";
    import PickupNamesCreateComponent from "../../../components/settings/cashier/PickupNamesCreateComponent";
    export default {
        name: "PickupNamesCreate",
        components: {PickupNamesCreateComponent, PageTitle},
        data: () => ({
            icon: ' pe-7s-plane icon-gradient bg-tempting-azure',
        })
    }
</script>

<style scoped>

</style>